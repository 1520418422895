/* thai */
@font-face {
  font-family: 'FC Iconic Text';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('FCIconicTextLight'), local('FC Iconic Text Light'), url(./Fonts/FC-Iconic-Text-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'FC Iconic Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('FCIconicTextRegular'), local('FC Iconic Text Regular'), url(./Fonts/FC-Iconic-Text-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'FC Iconic Text';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('FCIconicTextMedium'), local('FC Iconic Text Medium'), url(./Fonts/FC-Iconic-Text-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'FC Iconic Text';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('FCIconicTextSemiBold'), local('FC Iconic Text SemiBold'), url(./Fonts/FC-Iconic-Text-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'FC Iconic Text';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('FCIconicTextBold'), local('FC Iconic Text Bold'), url(./Fonts/FC-Iconic-Text-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "swiper/swiper-bundle.css";
@import 'react-toastify/dist/ReactToastify.css';
